export var IconStyle;
(function (IconStyle) {
    // Regular
    IconStyle["Regular"] = "regular";
    IconStyle["SharpRegular"] = "sharp-regular";
    // Solid
    IconStyle["Solid"] = "solid";
    IconStyle["SharpSolid"] = "sharp-solid";
    // Duotone
    IconStyle["Duotone"] = "duotone";
    IconStyle["SharpDuotoneSolid"] = "sharp-duotone-solid";
    // Light
    IconStyle["Light"] = "light";
    IconStyle["SharpLight"] = "sharp-light";
    // Thin
    IconStyle["Thin"] = "thin";
    IconStyle["SharpThin"] = "sharp-thin";
})(IconStyle || (IconStyle = {}));
